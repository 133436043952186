import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Button } from '@honehealth/components';
import mixpanel from 'mixpanel-browser';
import { facebookEvent } from '../components/utils/MonitorUtils';
import { myContext } from '../components/utils/ContextProvider';
import { usaStates, servedStates } from '../components/utils/Data';
import toast, { Toaster } from 'react-hot-toast';
import { Select, Option } from '@honehealth/components';
import { correctUrl, pushDataToGTM } from '../components/utils/Utils';
import NavBar from '../components/Navbar';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import CTAButton from '../components/CTAButton';

//takes in the state value the user selects from the drop down
//checks if that state is in the array of permitted state strings
//if so, return true, else false.
const validateState = (state) => {
  let servedStatus = servedStates.includes(state);
  return servedStatus;
};

export default function ValidateUser() {
  mixpanel.init(process.env.MIXPANEL_KEY);
  const globalContext = useContext(myContext);
  const flags = useFlags();

  const [selectedState, setSelectedState] = useState('');

  const setStorage = () => {
    localStorage.setItem('firstName', globalContext.firstName);
    localStorage.setItem('lastName', globalContext.lastName);
    localStorage.setItem('emailAddress', globalContext.emailAddress);
    localStorage.setItem('selectedState', selectedState);
    localStorage.setItem('hasEnteredData', 'true');
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      correctUrl('validate', window.location.href)
    ) {
      let leadDedupeId = facebookEvent(globalContext, 'Lead');
      mixpanel.track('Lead', {
        firstName: globalContext.firstName,
        lastName: globalContext.lastName,
        Email: globalContext.emailAddress,
      });
      pushDataToGTM({
        event: 'Lead',
      });
      if (window.gtag != null) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-671654442/yYvICMnR7b4CEKrEosAC',
        });
      }
    }
  }, []);

  const handleContinue = (e) => {
    e.preventDefault();
    if (selectedState === 'Select State') {
      toast('Please select your state to continue!');
    } else {
      globalContext.changeSelectedState(selectedState);
      setStorage();
      const nextStep = '/onepagecheckout';
      let location = validateState(selectedState)
        ? nextStep
        : '/nostate';

      mixpanel.track('Submitted State');
      navigate(`${location}`);
    }
  }

  return (
    <>
      <NavBar />
      <div className="min-h-screen flex items-center justify-center">
        <title>Select State Step</title>
        <div className="bg-softWhite w-5/6 p-4 lg:p-8 rounded shadow-2xl lg:w-1/3 lg:max-h-4/5">
          <div className="space-y-3 p-2 pt-4">
            <p className="text-red-600 text-center">One final question…</p>
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-black text-center">
              Where do you live?
            </h1>
            <h4 className="text-center text-lg">
              We just need to make sure we’re licensed in your state.
            </h4>
          </div>
          <div className="py-4 w-full">
            <Select
              className="w-full"
              label="Select State"
              id="state"
              maxOptionsHeight={40}
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              {[(
                <Option key="label" value="" isDisabled>
                  Select State
                </Option>
              ), ...usaStates.map((stateObj, index) => {
                return (
                  <Option className="w-full" key={index} value={stateObj.value}>
                    {stateObj.label}
                  </Option>
                );
              })]}
            </Select>
          </div>
          <CTAButton
            onClick={handleContinue}
          >
            CONTINUE
          </CTAButton>
          <Toaster
            containerStyle={{ position: 'absolute' }}
            toastOptions={{
              className: '',
              style: {
                color: 'rgba(252, 252, 250)',
                backgroundColor: 'rgba(239, 50, 25)',
                fontFamily: 'inter',
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
